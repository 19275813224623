import React, { Fragment, useEffect, useState } from "react";
import "./Geofences.css";
import { OlaMaps } from "../../../libs/OlaMapsWebSDK/olamaps-web-sdk.umd.js";

const Geofences = () => {
  const [geofencesData, setGeofencesData] = useState([]);
  const [selectedGeofenceData, setSelectedGeofenceData] = useState();
  const [filteredGeofences, setFilteredGeofences] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    latitude: "",
    longitude: "",
    radius: "",
  });

  //geofence Cricle
  const createCircle = (center, radiusInKm, numPoints = 64) => {
    const earthRadius = 6371; // Earth radius in km
    const [lng, lat] = center;
    const coordinates = [];

    for (let i = 0; i <= numPoints; i++) {
      const angle = (i * 360) / numPoints;
      const radians = (Math.PI / 180) * angle;
      const dx = (radiusInKm / earthRadius) * Math.cos(radians);
      const dy = (radiusInKm / earthRadius) * Math.sin(radians);

      const newLat = lat + (dy * 180) / Math.PI;
      const newLng =
        lng + (dx * 180) / (Math.PI * Math.cos((lat * Math.PI) / 180));
      coordinates.push([newLng, newLat]);
    }

    coordinates.push(coordinates[0]); // Close the polygon
    return {
      type: "Polygon",
      coordinates: [coordinates],
    };
  };

  //geofence creation form submit
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const geofenceData = {
      geoFenceName: formData.name.trim(), // Ensure it's a valid string
      latitude: parseFloat(formData.latitude),
      longitude: parseFloat(formData.longitude),
      radius: parseFloat(formData.radius),
    };

    console.log("Sending data:", geofenceData); // Debug log

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/v0/geofence-creation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(geofenceData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send data: ${errorText}`);
      }

      const result = await response.json();
      console.log("Success:", result);

      setGeofencesData((prevGeofences) => [
        ...prevGeofences,
        {
          name: geofenceData.geoFenceName,
          center: [geofenceData.longitude, geofenceData.latitude],
          radius: geofenceData.radius,
        },
      ]);
      setFormData({ name: "", latitude: "", longitude: "", radius: "" });
      setIsFormVisible(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //geofences from database render
  useEffect(() => {
    const fetchGeofences = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/v0/geofences`
        );
        const geofences = await response.json();

        if (Array.isArray(geofences)) {
          const formattedGeofences = geofences.map((geofence) => ({
            name: geofence.geoFenceName,
            center: [geofence.longitude, geofence.latitude],
            radius: geofence.radius,
          }));
          setGeofencesData(formattedGeofences);
          setFilteredGeofences(formattedGeofences); // Initialize filtered list
        } else {
          console.error("API response is not an array:", geofences);
        }
      } catch (error) {
        console.error("Failed to fetch geofences:", error);
      }
    };
    fetchGeofences();
  }, []);

  // Filter geofences based on search query
  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredGeofences(geofencesData);
      return;
    }

    const filtered = geofencesData.filter((geo) =>
      geo.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredGeofences(filtered);
  }, [searchQuery, geofencesData]);

  const selectGeofence = (geofence) => {
    setSelectedGeofenceData(geofence);
  };
  //map render
  useEffect(() => {
    const apiKey = process.env.REACT_APP_OLA_MAPS_API_KEY;
    if (!apiKey) {
      console.error("Ola Maps API key is missing.");
      return;
    }

    let myMap;

    try {
      const olaMaps = new OlaMaps({ apiKey });
      const container = document.getElementById("geofence-map-container");
      if (!container) {
        console.error(
          'Map container with id "geofence-map-container" not found.'
        );
        return;
      }

      myMap = olaMaps.init({
        container: "geofence-map-container",

        style:
          "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
        center: [72.97043, 19.172254],
        zoom: 12,
      });

      myMap.on("load", () => {
        const geofences = {
          type: "FeatureCollection",
          features: geofencesData.map((geofence) => ({
            type: "Feature",
            geometry: createCircle(geofence.center, geofence.radius),
            properties: { name: geofence.name },
          })),
        };
        const selectedGeofence = {
          type: "FeatureCollection",
          features: selectedGeofenceData,
        };

        myMap.addSource("geofences", {
          type: "geojson",
          data: geofences,
        });

        myMap.addSource("selected-geofences", {
          type: "geojson",
          data: selectedGeofence,
        });

        myMap.addLayer({
          id: "geofence-layer",
          type: "fill",
          source: "geofences",
          paint: {
            "fill-color": "#008000",
            "fill-opacity": 0.3,
          },
        });
        myMap.addLayer({
          id: "geofence-layer",
          type: "fill",
          source: "selectedGeofence",
          paint: {
            "fill-color": "pink", //new color here for selection
            "fill-opacity": 0.7,
          },
        });

        myMap.addLayer({
          id: "geofence-border",
          type: "line",
          source: "geofences",
          paint: {
            "line-color": "#000000",
            "line-width": 2,
          },
        });
      });
    } catch (error) {
      console.error("Failed to initialize Ola Maps:", error);
    }

    return () => {
      if (myMap) myMap.remove();
    };
  }, [geofencesData, selectedGeofenceData]);

  return (
    <Fragment>
      <div className="geofence-header">
        <input
          type="text"
          className="search-input"
          placeholder="Search for Geofences"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="actions">
          <button className="bulk-upload">Bulk Upload</button>
          <button
            className="create-geofence"
            onClick={() => setIsFormVisible(true)}
          >
            Create new Geofence
          </button>
        </div>
      </div>
      {/* Display filtered geofences */}
      <div className="geofence-list">
        {filteredGeofences.length > 0 ? (
          filteredGeofences.map((geo, index) => (
            <div
              key={index}
              className="geofence-item"
              onClick={() => selectGeofence(geo)}
            >
              <h4>{geo.name}</h4>
              <p>Latitude: {geo.center[1]}</p>
              <p>Longitude: {geo.center[0]}</p>
              <p>Radius: {geo.radius} km</p>
            </div>
          ))
        ) : (
          <p className="no-results">No geofences found.</p>
        )}
      </div>

      {isFormVisible && (
        <div className="geofence-form-overlay">
          <div className="geofence-form-container">
            <h2>Create New Geofence</h2>
            <form onSubmit={handleFormSubmit}>
              <input
                type="text"
                placeholder="Geofence Name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
              />
              <input
                type="number"
                step="any"
                placeholder="Latitude"
                value={formData.latitude}
                onChange={(e) =>
                  setFormData({ ...formData, latitude: e.target.value })
                }
                required
              />
              <input
                type="number"
                step="any"
                placeholder="Longitude"
                value={formData.longitude}
                onChange={(e) =>
                  setFormData({ ...formData, longitude: e.target.value })
                }
                required
              />
              <input
                type="number"
                step="any"
                placeholder="Radius (km)"
                value={formData.radius}
                onChange={(e) =>
                  setFormData({ ...formData, radius: e.target.value })
                }
                required
              />
              <button type="submit" className="submit-button">
                Save Geofence
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={() => setIsFormVisible(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      <div
        id="geofence-map-container"
        style={{ height: "100vh", width: "100%" }}
      ></div>
    </Fragment>
  );
};

export default Geofences;
