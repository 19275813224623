import React, { useState } from "react";
import DropDown from "./DropDown";
import "./LeftSidebar.css";

const LeftSidebar = ({ setActiveComponent }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const menuItems = [
    {
      name: "Map View",
      dropdown: [],
    },
    {
      name: "Fleet Operations",
      dropdown: [
        "Alerts",
        "Geofences",
        "Trips",
        "Route Optimization",
        "Reports",
      ],
    },
    {
      name: "Fleet Utilization",
      dropdown: [
        "Unscheduled Stoppage",
        "Geofence Stoppage",
        "Idling",
        "Daily Running Km",
      ],
    },
    {
      name: "Fleet Maintainence",
      dropdown: ["Tyre Maintenance", "Vehicle Service", "Workshop"],
    },
    {
      name: "Administration",
      dropdown: ["Vehicle", "User", "Driver","Vehicle Document"],
    },
    {
      name: "Subscription",
      dropdown: ["Manage Subscription", "Payments", "Payout"],
    },
    {
      name: "Driver",
      dropdown: ["Driver Training"],
    },
    {
      name: "Support",
      dropdown: ["Veyza Support"],
    },
  ];

  const toggleDropdown = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const handleDropdownItemClick = (item) => {
    setActiveComponent(item);
  };

  const toggleSidebarVisibility = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div className={`sidebar-container ${isSidebarVisible ? "" : "hidden"}`}>
      {
        <button
          style={{ display: "none" }}
          className={`toggle-button ${!isSidebarVisible ? "move-left" : ""}`}
          onClick={toggleSidebarVisibility}
        >
          {/*isSidebarVisible ? "Hide" : "Show"*/}
        </button>
      }
      {isSidebarVisible && (
        <div className="sidebar">
          <ul className="sidebar-menu">
            {menuItems.map((item, index) => (
              <li key={index} className="sidebar-item">
                <div
                  className="menu-item"
                  onClick={() => {
                    toggleDropdown(index);
                    if (!item.dropdown.length) setActiveComponent(item.name);
                  }}
                >
                  {item.name}
                </div>
                {openDropdown === index && item.dropdown.length > 0 && (
                  <DropDown
                    items={item.dropdown}
                    onItemClick={handleDropdownItemClick}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LeftSidebar;
