import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import "./VehicleDocument.css";

const VehicleDocument = () => {
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const charts = [
      {
        title: "Fitness",
        data: [
          { name: ">90 days", value: 70, color: "#4caf50" },
          { name: ">60 days", value: 20, color: "#ff9800" },
          { name: "<30 days", value: 10, color: "#f44336" },
        ],
      },
      {
        title: "Insurance",
        data: [
          { name: ">90 days", value: 50, color: "#4caf50" },
          { name: ">60 days", value: 30, color: "#ff9800" },
          { name: "<30 days", value: 20, color: "#f44336" },
        ],
      },
      {
        title: "National Permit",
        data: [
          { name: ">90 days", value: 60, color: "#4caf50" },
          { name: ">60 days", value: 25, color: "#ff9800" },
          { name: "<30 days", value: 15, color: "#f44336" },
        ],
      },
      {
        title: "Tax Certificate",
        data: [
          { name: ">90 days", value: 65, color: "#4caf50" },
          { name: ">60 days", value: 20, color: "#ff9800" },
          { name: "<30 days", value: 15, color: "#f44336" },
        ],
      },
      {
        title: "PUC",
        data: [
          { name: ">90 days", value: 80, color: "#4caf50" },
          { name: ">60 days", value: 10, color: "#ff9800" },
          { name: "<30 days", value: 10, color: "#f44336" },
        ],
      },
      {
        title: "State Permit",
        data: [
          { name: ">90 days", value: 55, color: "#4caf50" },
          { name: ">60 days", value: 30, color: "#ff9800" },
          { name: "<30 days", value: 15, color: "#f44336" },
        ],
      },
    ];
    setChartData(charts);

    const table = Array.from({ length: 10 }).map((_, i) => ({
      regNo: `55AV45${90 + i}`,
      fitness: i % 3 === 0 ? ">90" : ">60",
      insurance: i % 4 === 0 ? ">30" : ">90",
      statePermit: i % 2 === 0 ? ">60" : ">30",
      taxCertificate: i % 5 === 0 ? ">90" : "<30",
      fitnessValidity: `11 Aug ${2025 - i}`,
      insuranceValidity: `26 Jul ${2025 - i}`,
    }));
    setTableData(table);
  };

  return (
    <div className="vehicle-document-container">
      {/* Charts Section */}
      <div className="charts-container">
        {chartData.map((chart, index) => (
          <div key={index} className="chart-card">
            <h3>{chart.title}</h3>
            <ResponsiveContainer width="90%" height={200}>
              <PieChart>
                <Pie
                  data={chart.data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80} // Adjusted size
                  label={false} // Hide labels by default
                >
                  {chart.data.map((entry, idx) => (
                    <Cell key={idx} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip cursor={{ fill: "rgba(0, 0, 0, 0.1)" }} />
                <Legend verticalAlign="bottom" height={30} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        ))}
      </div>

      {/* Table Section */}
      <div className="table-container">
        <h3>Document Summary</h3>
        <table className="data-table">
          <thead>
            <tr>
              <th>Truck Regn No</th>
              <th>Fitness Bucket</th>
              <th>Insurance Bucket</th>
              <th>State Permit Bucket</th>
              <th>Tax Certificate Bucket</th>
              <th>Validity of Fitness</th>
              <th>Validity of Insurance</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.regNo}</td>
                <td>{row.fitness}</td>
                <td>{row.insurance}</td>
                <td>{row.statePermit}</td>
                <td>{row.taxCertificate}</td>
                <td>{row.fitnessValidity}</td>
                <td>{row.insuranceValidity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VehicleDocument;

