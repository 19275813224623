import React from "react";
import ourStoryImage from "../assets/ourStory.webp";

const OurStory = () => {
  return (
    <div
      id="about"
      className="story-section"
      style={{ backgroundImage: `url(${ourStoryImage})` }}
    >
      {/*To add a bit darkness over background Image */}
      <div className="story-overlay">
        <div className="story-content">
          {/*Left Part */}
          <h1 className="story-title">
            <span>Our</span> Story
          </h1>

          {/*Right Part */}
          <div className="story-text">
            <h2 className="story-subtitle">Veyza</h2>
            <p>
              Veyza is more than just a logistics technology company; it's a
              dedicated partner in your journey towards operational success. We
              focus on providing innovative tools and actionable insights that
              help businesses optimize their fleets. With our commitment to
              enhancing performance and reducing costs, we aim to support you in
              achieving your logistics goals, regardless of your current stage.
              Join us in redefining your logistics capabilities for a more
              productive future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
