// importing necessary libraries
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//importing logo
import logo from "../assets/logoVeyzaDark.png";

const Header = () => {
  //initiating navigation prebuilt in react
  const navigate = useNavigate();

  //to toggle menu for mobile devices
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  //function to help to scroll to the component clicked in header(navbar)

  const handleScroll = (id) => {
    const target = document.getElementById(id);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    //in case of mobile menu close it after redirect
    setIsMenuOpen(false);
  };

  //function to handle login redirect by requesting login page
  const handleLoginRedirect = () => {
    navigate("/login");
    //in case of mobile menu close it after redirect
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      {/* Logo on Header*/}
      <div className="navbar-logo">
        <img src={logo} alt="Veyza Logo" />
      </div>

      {/* Burger Icon */}
      <div className="burger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <span className="burger-bar"></span>
        <span className="burger-bar"></span>
        <span className="burger-bar"></span>
      </div>

      {/*Scroll/Redirect menu on Header */}
      <ul className={`navbar-links ${isMenuOpen ? "active" : ""}`}>
        {/*About */}
        <li>
          <button className="nav-button" onClick={() => handleScroll("about")}>
            About
          </button>
        </li>

        {/*Services */}
        <li>
          <button
            className="nav-button"
            onClick={() => handleScroll("services")}
          >
            Services
          </button>
        </li>

        {/* Reviews/Testimonials  */}
        <li>
          <button
            className="nav-button"
            onClick={() => handleScroll("testimonials")}
          >
            Testimonials
          </button>
        </li>

        {/*Contact Us */}
        <li>
          <button
            className="nav-button"
            onClick={() => handleScroll("contacts")}
          >
            Contacts
          </button>
        </li>

        {/*Login Redirect */}
        <li>
          <button className="nav-button" onClick={handleLoginRedirect}>
            Login
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
