//importing required packages
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

//importing required files
import "./styles/App.css";

//importing pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile.jsx";
import Dashboard from "./Components/UserDashboard/Dashboard";
// importing necessary components
import NotFound from "./Components/NotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/*This component would be rendered when any non existing or falsely manipulated url tries to make a request  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
