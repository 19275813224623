import React, { useEffect, useState } from "react";
import "./VeyzaSupport.css";

const VeyzaSupport = () => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [formError, setFormError] = useState("");
  const [tickets, setTickets] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [chatStep, setChatStep] = useState(0);
  const [problemDescription, setProblemDescription] = useState("");
  const complaintTypes = ["Software Issue"];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !vehicleNumber ||
      !contactName ||
      !email ||
      !complaintType ||
      !subject
    ) {
      setFormError("All required fields must be filled out.");
      return;
    }
    setFormError("");
    setIsSubmitting(true);
    const newTicket = {
      ticketNumber: tickets.length + 1,
      vehicleNumber,
      contactName,
      email,
      complaintType,
      subject,
      description,
      date: new Date().toLocaleDateString(),
      status: "Open",
    };
    console.log("New ticket data: ", newTicket);
    try {
      const response = await fetch(process.env.REACT_APP_SUPPORT_SHEET_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newTicket),
        mode: "no-cors",
      });
      if (!response.ok) throw new Error(`Failed to submit ticket.`);
      setVehicleNumber("");
      setContactName("");
      setEmail("");
      setComplaintType("");
      setSubject("");
      setDescription("");
      setTickets((prevTickets) => [newTicket, ...prevTickets]); // Keep only the latest 5 tickets
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SUPPORT_SHEET_URL);
        const ticketsData = await response.json();
        setTickets(ticketsData); // Keep only the latest 5 tickets
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };
    fetchTickets();
  }, []);

  const handleChatbotSubmit = async () => {
    if (!problemDescription) return;

    const newTicket = {
      ticketNumber: `VS${tickets.length + 1}`,
      vehicleNumber: "N/A",
      contactName: "Chatbot User",
      email: "N/A",
      complaintType: "Software Issue",
      subject: "Chatbot Complaint",
      description: problemDescription,
      ticketDate: new Date().toLocaleDateString(),
      status: "Open",
    };

    setTickets((prevTickets) => [newTicket, ...prevTickets]);
    setChatbotOpen(false);
    setProblemDescription("");
    setChatStep(0);

    try {
      const response = await fetch(process.env.REACT_APP_SUPPORT_SHEET_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newTicket),
        mode: "no-cors",
      });
      if (!response.ok) throw new Error(`Failed to submit chatbot ticket.`);
    } catch (error) {
      console.error("Error submitting chatbot ticket:", error);
    }
  };

  return (
    <div className="support-container">
      <h2>Veyza Support</h2>
      <p>If you're facing any issues, please fill out the form below.</p>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Vehicle Number</label>
          <input
            type="text"
            placeholder="Enter Vehicle Number"
            value={vehicleNumber}
            onChange={(e) => setVehicleNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Contact Name</label>
          <input
            type="text"
            placeholder="Enter Contact Name"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email *</label>
          <input
            type="email"
            placeholder="Enter Email Address"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Complaint Type *</label>
          <select
            required
            value={complaintType}
            onChange={(e) => setComplaintType(e.target.value)}
          >
            <option value="">Select Complaint Type</option>
            {complaintTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Subject *</label>
          <input
            type="text"
            placeholder="Enter Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            rows="5"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        {formError && <p className="error-message">{formError}</p>}
        <div className="form-actions">
          <button type="reset" className="reset-btn" disabled={isSubmitting}>
            Reset
          </button>
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>

      <table className="ticket-table">
        <thead>
          <tr>
            <th>Ticket No</th>
            <th>Subject</th>
            <th>Status</th>
            <th>Date Created</th>
          </tr>
        </thead>
        <tbody>
          {tickets.length > 0 ? (
            tickets.map((ticket) => (
              <tr key={ticket.ticketNumber}>
                <td>{ticket.ticketNumber}</td>
                <td>{ticket.subject}</td>
                <td>{ticket.status}</td>
                <td>{ticket.ticketDate}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" style={{ textAlign: "center" }}>
                No tickets created yet.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <button
        className="chatbot-button"
        onClick={() => setChatbotOpen(!chatbotOpen)}
      >
        💬
      </button>

      {chatbotOpen && (
        <div className="chatbot-modal">
          <div className="chatbot-header">
            <span>Support Chat</span>
            <button onClick={() => setChatbotOpen(false)}>×</button>
          </div>
          <div className="chatbot-body">
            {chatStep === 0 && (
              <>
                <p>Hello! How can we assist you?</p>
                <button onClick={() => setChatStep(1)}>File a Complaint</button>
              </>
            )}
            {chatStep === 1 && (
              <>
                <p>Select your complaint type:</p>
                <button onClick={() => setChatStep(2)}>Software Issue</button>
                <button onClick={() => setChatStep(3)}>Other</button>
              </>
            )}
            {chatStep === 2 && (
              <>
                <p>What problem are you facing?</p>
                <textarea
                  placeholder="Describe your issue"
                  value={problemDescription}
                  onChange={(e) => setProblemDescription(e.target.value)}
                ></textarea>
                <button
                  onClick={handleChatbotSubmit}
                  disabled={!problemDescription}
                >
                  Submit
                </button>
              </>
            )}
            {chatStep === 3 && (
              <>
                <p>
                  Please contact us at <strong>support@veyza.com</strong>.
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VeyzaSupport;
