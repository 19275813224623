import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import "./Driver.css";

const Driver = () => {
  const [drivers, setDrivers] = useState([]);
  const [showAddDriverForm, setShowAddDriverForm] = useState(false);
  const [newDriver, setNewDriver] = useState({
    driverName: "",
    contact: "",
    aadhar: "",
    dlNumber: "",
    dlIssueDate: "",
    dlExpiryDate: "",
    vehicleNumber: "",
    // aadharAttachment: null, // Commented out
    // dlFront: null, // Commented out
    // dlBack: null, // Commented out
  });

  // State to track which driver is being edited
  const [editingAadhar, setEditingAadhar] = useState(null);
  // State to hold the edited driver details temporarily
  const [editedDriver, setEditedDriver] = useState({});
  // Track delete popup visibility
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  //  Track which driver is being deleted
  const [driverToDelete, setDriverToDelete] = useState(null);

  //Function to fetch Drivers
  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/v0/veyza-drivers`
        ); // Replace with your API endpoint  {status:200,data;[]}
        // console.log(response);
        setDrivers(response.data);
      } catch (error) {
        console.error("Error fetching drivers:", error);
      }
    };
    fetchDrivers();
  }, []);

  //handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingAadhar) {
      setEditedDriver({ ...editedDriver, [name]: value });
    } else {
      setNewDriver({ ...newDriver, [name]: value });
    }
  };

  // Handle file uploads
  // const handleFileChange = (e) => {
  //   const { name, files } = e.target;
  //   setNewDriver({ ...newDriver, [name]: files[0] });
  // };

  //API Request to CREATE
  const handleAddDriver = async () => {
    try {
      console.log(newDriver);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/v0/add-veyza-drivers`, // Replace with your API endpoint
        newDriver
      );
      console.log(response);

      if (response.status === 201 || response.status === 200) {
        setDrivers([...drivers, response.data]); // Assuming the backend returns the new driver data
        setShowAddDriverForm(false);
        setNewDriver({
          driverName: "",
          contact: "",
          aadhar: "",
          dlNumber: "",
          dlIssueDate: "",
          dlExpiryDate: "",
          vehicleNumber: "",
        });
        alert("Driver added successfully!");
      } else {
        alert("Failed to add driver. Please try again.");
      }
    } catch (error) {
      console.error("Error adding driver:", error);
      alert("An error occurred while adding the driver.");
    }
  };

  // Enter edit mode for a specific driver for UPDATE
  const handleEditClick = (driver) => {
    setEditingAadhar(driver.aadhar); // Mark the selected driver as being edited
    setEditedDriver(driver); // Populate the edit state with the driver's current details
  };
  //cancel on editing
  const handleCancelClick = () => {
    setEditingAadhar(null); // Exit edit mode
    setEditedDriver({}); // Clear the temporary edit state
  };

  //API request to update
  const handleSaveClick = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/v0/update-veyza-driver/${editingAadhar}`,
        editedDriver // Send the updated driver data to the server
      );

      if (response.status === 200) {
        // Update the UI with the saved data
        setDrivers((prevDrivers) =>
          prevDrivers.map((driver) =>
            driver.aadhar === editingAadhar ? editedDriver : driver
          )
        );
        setEditingAadhar(null); // Exit edit mode
        alert("Driver updated successfully!");
      } else {
        alert("Failed to update driver. Please try again.");
      }
    } catch (error) {
      console.error("Error updating driver:", error);
      alert("An error occurred while updating the driver.");
    }
  };
  // Handle delete confirmation
  const handleDeleteClick = (driver) => {
    setDriverToDelete(driver);
    setShowDeletePopup(true);
  };
  //Deletion API CALL
  const confirmDelete = async () => {
    if (!driverToDelete) return;
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/v0/delete-veyza-driver/${driverToDelete.aadhar}`
      );

      if (response.status === 200) {
        setDrivers((prevDrivers) =>
          prevDrivers.filter(
            (driver) => driver.aadhar !== driverToDelete.aadhar
          )
        );
        alert("Driver deleted successfully!");
      } else {
        alert("Failed to delete driver. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting driver:", error);
      alert("An error occurred while deleting the driver.");
    } finally {
      setShowDeletePopup(false);
      setDriverToDelete(null);
    }
  };
  //Cancel Deletion
  const cancelDelete = () => {
    setShowDeletePopup(false);
    setDriverToDelete(null);
  };

  return (
    <div className="drivers-container">
      <header className="drivers-header">
        <h1>Drivers</h1>
        <div className="drivers-actions">
          <button className="bulk-upload-button">Driver Bulk Upload</button>
          <button
            className="add-driver-button"
            onClick={() => setShowAddDriverForm(!showAddDriverForm)}
          >
            Add Driver
          </button>
        </div>
      </header>

      {/* Add Driver Form */}
      {showAddDriverForm && (
        <div className="add-driver-form">
          <h2>Add New Driver</h2>
          <form className="form-grid">
            <div className="form-field">
              <label>Driver Name:</label>
              <input
                type="text"
                name="driverName"
                value={newDriver.driverName}
                onChange={handleInputChange}
                placeholder="Enter driver name"
                required
              />
            </div>
            <div className="form-field">
              <label>Contact Number:</label>
              <input
                type="tel"
                name="contact"
                maxLength={10}
                value={newDriver.contact}
                onChange={handleInputChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="form-field">
              <label>Aadhar Number:</label>
              <input
                type="text"
                name="aadhar"
                maxLength={12}
                value={newDriver.aadhar}
                onChange={handleInputChange}
                placeholder="Enter Aadhar number"
                required
              />
            </div>
            <div className="form-field">
              <label>DL Number:</label>
              <input
                type="text"
                name="dlNumber"
                value={newDriver.dlNumber}
                onChange={handleInputChange}
                placeholder="Enter DL number"
                required
              />
            </div>
            <div className="form-field">
              <label>DL Date of Issue:</label>
              <input
                type="date"
                name="dlIssueDate"
                value={newDriver.dlIssueDate}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-field">
              <label>DL Date of Expiry:</label>
              <input
                type="date"
                name="dlExpiryDate"
                value={newDriver.dlExpiryDate}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-field">
              <label>Vehicle Number:</label>
              <input
                type="text"
                name="vehicleNumber"
                value={newDriver.vehicleNumber}
                onChange={handleInputChange}
                placeholder="Enter vehicle number"
                required
              />
            </div>

            {/* Commented Out Attachment Fields */}
            {/* <div className="form-field">
              <label>Aadhar Attachment:</label>
              <input
                type="file"
                name="aadharAttachment"
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="form-field">
              <label>DL Front Image:</label>
              <input
                type="file"
                name="dlFront"
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="form-field">
              <label>DL Back Image:</label>
              <input
                type="file"
                name="dlBack"
                onChange={handleFileChange}
                required
              />
            </div> */}

            <div className="form-actions">
              <button type="button" onClick={handleAddDriver}>
                Submit
              </button>
              <button type="cancel" onClick={() => setShowAddDriverForm(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="drivers-table">
        <table>
          <thead>
            <tr>
              <th>Driver Name</th>
              <th>Contact</th>
              <th>Aadhar</th>
              <th>DL Number</th>
              <th>DL Issue Date</th>
              <th>DL Expiry Date</th>
              <th>Vehicle Number</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver) => (
              <tr key={driver.aadhar}>
                {editingAadhar === driver.aadhar ? ( // New: Check if this row is in edit mode
                  <Fragment>
                    {/* New: Render input fields for editing */}
                    <td>
                      <input
                        type="text"
                        name="driverName"
                        value={editedDriver.driverName}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="contact"
                        value={editedDriver.contact}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>{driver.aadhar}</td> {/* Aadhar is not editable */}
                    <td>
                      <input
                        type="text"
                        name="dlNumber"
                        value={editedDriver.dlNumber}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        name="dlIssueDate"
                        value={editedDriver.dlIssueDate}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        name="dlExpiryDate"
                        value={editedDriver.dlExpiryDate}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="vehicleNumber"
                        value={editedDriver.vehicleNumber}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <button className="save-button" onClick={handleSaveClick}>
                        Save
                      </button>{" "}
                      {/* Save changes */}
                      <button
                        className="cancel-button"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>{" "}
                      {/* Cancel editing */}
                    </td>
                  </Fragment>
                ) : (
                  <Fragment>
                    {/* Pre-existing: Display the driver's data */}
                    <td>{driver.driverName}</td>
                    <td>{driver.contact}</td>
                    <td>{driver.aadhar}</td>
                    <td>{driver.dlNumber}</td>
                    <td>
                      {new Date(driver.dlIssueDate).toLocaleDateString("en-GB")}
                    </td>
                    <td>
                      {new Date(driver.dlExpiryDate).toLocaleDateString(
                        "en-GB"
                      )}
                    </td>
                    <td>{driver.vehicleNumber}</td>
                    <td>
                      <button
                        className="edit-button"
                        onClick={() => handleEditClick(driver)}
                      >
                        <i className="fas fa-edit"></i> Edit
                      </button>{" "}
                      {/* New: Edit button */}
                    </td>
                    <td>
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteClick(driver)}
                      >
                        <i className="fas fa-trash"></i> Delete
                      </button>
                    </td>
                  </Fragment>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* New: Delete Confirmation Popup */}
      {showDeletePopup && driverToDelete && (
        <div className="delete-popup">
          <div className="delete-popup-content">
            <h2>Delete Driver</h2>
            <p>
              Are you sure you want to delete driver{" "}
              <strong>{driverToDelete.driverName}</strong> with Aadhar number{" "}
              <strong>{driverToDelete.aadhar}</strong>? This action is permanent
              and cannot be undone.
            </p>
            <div className="popup-actions">
              <button onClick={confirmDelete}>Delete</button>
              <button onClick={cancelDelete}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Driver;

// Edit and delete of driver can not be changed from here
