import React, { useState } from "react";
import driver from "../assets/driver.jpg";

const ContactForm = () => {
  // To locally store data(temporarily) before sending it onto the excel sheet or when user is entering values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    fleetSize: "",
    message: "",
  });
  //to avoid redundancy
  // In order to disable button so user does not make multiple clicks to submit
  const [isSending, setIsSending] = useState(false);

  // To change field values while user is filling form
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  // Function initiated to submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      /*REACT_APP_SHEETS_URL consists the URL of excel file it is shared only with authorized personel and the deployment service used  */
      const response = await fetch(process.env.REACT_APP_SHEETS_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors", // Keep the no-cors mode on localhost
        body: JSON.stringify(formData),
      });
      console.log(response);
      // all code lines commented below does not work over localhost so they would be uncommented when deployed

      // if (response.ok) {
      alert("Message sent successfully!");
      setFormData({
        name: "",
        email: "",
        contactNumber: "",
        fleetSize: "",
        message: "",
      });
      // } else {
      // alert("Failed to send message. Please try again.");
      // }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error sending message. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="contact-section" id="contacts">
      <div className="contact-container">
        {/* Image Section  for the image of driver on form */}
        <div className="contact-image">
          <img src={driver} alt="Professional Driver" />
        </div>

        {/* Form Section */}
        <div className="contact-form">
          <h2>Get in touch with us</h2>
          <p>Feel free to contact us for more information.</p>

          <div className="contact-info">
            {/*This tag would let someone directly mail at sales@veyza.in */}
            <p>
              📍 E-257, 2nd Floor, Industrial Area, Phase-8B, Mohali, SAS Nagar,
              Punjab, 160062
            </p>
            <p>
              📧 <a href="mailto:sales@veyza.in">sales@veyza.in</a>
            </p>
            <p>📞 0172 - 000000</p>
          </div>

          <form onSubmit={handleSubmit}>
            {/*Name Field */}
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              placeholder="Your name here"
              required
              value={formData.name}
              onChange={handleChange}
            />

            {/*Email Field */}
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Your email here"
              required
              value={formData.email}
              onChange={handleChange}
            />

            <div className="row">
              <div className="input-group">
                {/*Mobile Number */}
                <label htmlFor="contact">Mobile Number</label>
                <input
                  type="text"
                  id="contactNumber"
                  placeholder="Your Mobile Number here"
                  maxLength={10}
                  required
                  value={formData.contactNumber}
                  onChange={handleChange}
                />
              </div>

              <div className="input-group">
                {/*Fleet Size */}
                <label htmlFor="fleetSize">Fleet Size</label>
                <input
                  type="number"
                  id="fleetSize"
                  placeholder="Your Fleet Size here"
                  step={1}
                  required
                  value={formData.fleetSize}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/*Message */}
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              rows="4"
              placeholder="Your message here"
              required
              value={formData.message}
              onChange={handleChange}
            ></textarea>

            <button
              type="submit"
              className="submit-button"
              // disabled between once clicked and response sent to avoid redundant clicks
              disabled={isSending}
            >
              {isSending ? "Sending...." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
