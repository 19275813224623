import React from 'react';
import './Payout.css'; // Importing the CSS file

function Payout() {
  const handleVendorPayout = () => {
    // Logic for vendor payout integration will go here
    console.log('Vendor Payout button clicked');
  };

  const handleDriverPayout = () => {
    // Logic for driver payout integration will go here
    console.log('Driver Payout button clicked');
  };

  return (
    <div className="payout-container">
      <h1 className="payout-title">Payout</h1>
      <div className="button-group">
        <button className="payout-button" onClick={handleVendorPayout}>
          Vendor Payout
        </button>
        <button className="payout-button" onClick={handleDriverPayout}>
          Driver Payout
        </button>
      </div>
    </div>
  );
}

export default Payout;
