import React, { useState } from "react";
import "./Reports.css";

function ReportsPage() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedParameters, setSelectedParameters] = useState([]);

  const reportParameters = [
    "Search Parameter",
    "Actual End Time & Location",
    "Actual Start Time & Location",
    "Alert Address",
    "Alert Duration",
    "Alert Location",
    "Average Speed (kmph)",
    "Both Engine On Hours",
    "Date",
    "Date Duration",
    "Hardware Device Type",
    "Driver Name",
    "Duration",
    "Emission Type",
    "End Address",
    "End Time",
    "Engine ON Hours",
    "Engine On Time",
    "Event Type",
    "Exceptions",
    "Final Fuel Level",
    "Fuel Added (L)",
    "Fuel Added Time And Location",
    "Fuel Consumed (L)",
    "Fuel Consumed During Idling (L)",
    "Fuel Consumed Per Hour (L/hr)",
    "Fuel Sensor Model",
    "Fuel Tank Capacity",
    "Group Name",
    "Initial Fuel Level",
    "Fuel Sensor Installed",
    "Max Speed (kmph)",
    "Mileage (kmpl)",
    "Min Speed (kmph)",
    "Asset Movement Arm OFF",
    "Asset Movement Arm OFF Fuel (L)",
    "Asset Movement Arm ON",
    "Asset Movement Arm ON Fuel (L)",
    "Pilferage Amount (L)",
    "Pilferage Count",
    "Primary Engine Fuel Consumption (L)",
    "Primary Engine ON Time(Hrs)",
    "Primary Engine FuelMileage (kmpl)",
    "Primary Engine FuelMileage (L/hr)",
    "Refuel Amount (L)",
    "Refuel Count",
    "Secondary Engine Fuel Consumption (L)",
    "Secondary Engine ON Time(Hrs)",
    "Secondary Engine FuelMileage (L/hr)",
    "Start Address",
    "Start Time",
    "Asset Stationary Arm OFF",
    "Asset stationary Arm OFF Fuel (L)",
    "Asset Stationary Arm ON",
    "Asset stationary Arm ON Fuel (L)",
    "Total Day Running Time (5 Am to 10 Pm)",
    "Total Distance (km)",
    "Total Distance Covered In A Day (km)",
    "Total Engine Hours",
    "Total Engine On Time(Hrs)",
    "Total Fuel Mileage (L/hr)",
    "Total HA Count",
    "Total Halt Time",
    "Total HB Count",
    "Idling Hours",
    "Total Idling Time",
    "Total Night Running Time (10 Pm to 5 Am)",
    "Total Overspeed Distance (km)",
    "Total Route Deviated Time",
    "Running Hours",
    "Total Running Time",
    "Total Run Time Fuel (L)",
    "Driving Score (%)",
    "Total Trips",
    "Trip Count",
    "Vehicle Class Description",
    "Vehicle Make",
    "Vehicle Model",
    "Vehicle Number",
    "Vehicle Type",
  ];

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleCheckboxChange = (param) => {
    setSelectedParameters((prevSelected) =>
      prevSelected.includes(param)
        ? prevSelected.filter((item) => item !== param)
        : [...prevSelected, param]
    );
  };

  return (
    <div className="reports-container">
      <h1>Reports</h1>
      <div className="search-bar">
        <input type="text" placeholder="Search for any report" />
        <button className="automated-reports-btn">
          <i className="icon"></i> AUTOMATED REPORTS
        </button>
        <button className="new-custom-report-btn" onClick={toggleDropdown}>
          <i className="icon"></i> NEW CUSTOM REPORT
        </button>
      </div>

      {showDropdown && (
        <div className="dropdown">
          <div className="dropdown-header">
            <p>Select Report Parameter</p>
          </div>
          <ul className="dropdown-list">
            {reportParameters.map((param, index) => (
              <li key={index} className="dropdown-item">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedParameters.includes(param)}
                    onChange={() => handleCheckboxChange(param)}
                  />
                  {param}
                </label>
              </li>
            ))}
          </ul>
          <div className="dropdown-footer">
            <button className="apply-btn">Apply</button>
            <button
              className="reset-btn"
              onClick={() => setShowDropdown(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReportsPage;
