import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RouteOptimization.css";
import { OlaMaps } from "../../../libs/OlaMapsWebSDK/olamaps-web-sdk.umd.js";

function RouteOptimization({ onSubmit }) {
  const [departureTime, setDepartureTime] = useState(new Date());
  const [showFuelInfo, setShowFuelInfo] = useState(false);
  const [fuelType, setFuelType] = useState("");
  const [fuelPrice, setFuelPrice] = useState("");
  const [startLocation, setStartLocation] = useState("");
  const [destination, setDestination] = useState("");
  const [additionalDestinations, setAdditionalDestinations] = useState([]); // New state for extra destinations
  const [vehicleType, setVehicleType] = useState("");
  const [truckAxle, setTruckAxle] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation
    if (!startLocation.trim()) {
      alert("Please enter the start location.");
      return;
    }
    if (!destination.trim()) {
      alert("Please enter the destination.");
      return;
    }
    if (vehicleType === "Truck" && !truckAxle) {
      alert("Please select a truck axle type.");
      return;
    }
    if (showFuelInfo && (!fuelType || !fuelPrice)) {
      alert("Please provide fuel type and price if fuel info is enabled.");
      return;
    }

    const formData = {
      departureTime,
      startLocation,
      destination,
      additionalDestinations,
      vehicleType,
      truckAxle: vehicleType === "Truck" ? truckAxle : null,
      fuelInfo: showFuelInfo ? { fuelType, fuelPrice } : null,
    };

    console.log("Form submitted with data:", formData);

    if (typeof onSubmit === "function") {
      onSubmit(formData);
    } else {
      console.warn("onSubmit function is not provided.");
    }
  };

  // Function to add additional destinations (max 4)
  const handleAddDestination = () => {
    if (additionalDestinations.length < 4) {
      setAdditionalDestinations([...additionalDestinations, ""]);
    } else {
      alert("You can only add up to 4 extra destinations.");
    }
  };

  // Function to update additional destination inputs
  const handleDestinationChange = (index, value) => {
    const updatedDestinations = [...additionalDestinations];
    updatedDestinations[index] = value;
    setAdditionalDestinations(updatedDestinations);
  };

  // Function to remove a destination
  const handleRemoveDestination = (index) => {
    const updatedDestinations = additionalDestinations.filter(
      (_, i) => i !== index
    );
    setAdditionalDestinations(updatedDestinations);
  };

  //map render
  useEffect(() => {
    const apiKey = process.env.REACT_APP_OLA_MAPS_API_KEY;
    if (!apiKey) {
      console.error("Ola Maps API key is missing.");
      return;
    }

    let myMap;

    try {
      const olaMaps = new OlaMaps({ apiKey });
      const container = document.getElementById("map-container");
      if (!container) {
        console.error('Map container with id "map-container" not found.');
        return;
      }

      myMap = olaMaps.init({
        container: "map-container",
        style:
          "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
        center: [78.9629, 20.5937],
        zoom: 5,
      });
    } catch (error) {
      console.error("Failed to initialize Ola Maps:", error);
    }

    return () => {
      if (myMap) myMap.remove();
    };
  }, []);
  return (
    <div className="route-optimization-layout">
      <div className="route-optimization-container">
        <h1>Route Optimization</h1>
        <form className="route-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Enter starting location"
              className="location-input"
              value={startLocation}
              onChange={(e) => setStartLocation(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              placeholder="Enter destination"
              className="location-input"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
            />
          </div>

          {/* Additional Destinations */}
          {additionalDestinations.map((dest, index) => (
            <div className="form-group additional-destination" key={index}>
              <input
                type="text"
                placeholder={`Enter stop ${index + 1}`}
                className="location-input"
                value={dest}
                onChange={(e) => handleDestinationChange(index, e.target.value)}
              />
              <button
                type="button"
                className="remove-destination-btn"
                onClick={() => handleRemoveDestination(index)}
              >
                ✖ Remove
              </button>
            </div>
          ))}

          {/* Add Destination Button */}
          {additionalDestinations.length < 4 && (
            <button
              type="button"
              className="add-destination-btn"
              onClick={handleAddDestination}
            >
              + Add Destination
            </button>
          )}

          <div className="form-group">
            <label>Select Vehicle Type</label>
            <select
              className="vehicle-select"
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
            >
              <option value="">Select Vehicle</option>
              <option value="Car">Car, Jeep, Van, SUV</option>
              <option value="Truck">Truck</option>
              <option value="Bus">Bus</option>
              <option value="Motorbike">Motorbike</option>
            </select>
          </div>

          {vehicleType === "Truck" && (
            <div className="form-group">
              <label>Select Truck Axle Type</label>
              <select
                className="truck-axle-select"
                value={truckAxle}
                onChange={(e) => setTruckAxle(e.target.value)}
                required
              >
                <option value="">Select Axle Type</option>
                <option value="2 Axles">Truck - 2 Axles</option>
                <option value="3 Axles">Truck - 3 Axles</option>
                <option value="4 Axles">Truck - 4 Axles</option>
                <option value="5 Axles">Truck - 5 Axles</option>
                <option value="6 Axles">Truck - 6 Axles</option>
                <option value="7 Axles">Truck - 7 Axles</option>
              </select>
            </div>
          )}

          <div className="form-group">
            <button
              type="button"
              className="fuel-info-btn"
              onClick={() => setShowFuelInfo((prev) => !prev)}
            >
              + Optional Fuel Info
            </button>
            {showFuelInfo && (
              <div className="fuel-info-dropdown">
                <div className="fuel-info-row">
                  <label>Fuel Type</label>
                  <select
                    className="fuel-type-select"
                    value={fuelType}
                    onChange={(e) => setFuelType(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    <option value="Petrol">Petrol</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Electric">Electric</option>
                  </select>
                </div>
                <div className="fuel-info-row">
                  <label>Fuel Price (per litre/kWh)</label>
                  <input
                    type="number"
                    placeholder="Enter fuel price"
                    className="fuel-price-input"
                    value={fuelPrice}
                    onChange={(e) => setFuelPrice(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="form-group">
            <label>Departure Time</label>
            <DatePicker
              selected={departureTime}
              onChange={(date) => setDepartureTime(date)}
              showTimeSelect
              timeFormat="hh:mm aa"
              timeIntervals={15}
              dateFormat="Pp"
              placeholderText="Select Date & Time"
              className="datetime-picker"
            />
          </div>

          <button type="submit" className="submit-btn">
            ✓ Submit
          </button>
        </form>
      </div>
      <div id="map-container" className="map-container">
        <h2>Map Integration</h2>
        <div className="map-placeholder">Map will appear here.</div>
      </div>
    </div>
  );
}

export default RouteOptimization;
