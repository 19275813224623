import React, { useEffect, useState, useCallback } from "react";
import { OlaMaps } from "../../../libs/OlaMapsWebSDK/olamaps-web-sdk.umd.js";
import axios from "axios";
import defaultTruck from "../../../assets/default.png";
import stoppedTruck from "../../../assets/stopped.png";
import movingTruck from "../../../assets/moving.png";
import notWorkingTruck from "../../../assets/not_working.png";
const StatusBar = ({ statuses, onStatusClick }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px 20px",
        backgroundColor: "#f8f9fa",
        borderBottom: "2px solid #e0e0e0",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      {statuses.map((status) => (
        <div
          key={status.key}
          onClick={() => onStatusClick(status.key)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
            transition: "transform 0.2s ease-in-out",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          <div
            style={{
              width: "14px",
              height: "14px",
              borderRadius: "50%",
              backgroundColor: status.color,
              marginBottom: "5px",
            }}
          ></div>
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            {status.count}
          </span>
          <span style={{ fontSize: "12px", color: "#555" }}>
            {status.label}
          </span>
        </div>
      ))}
    </div>
  );
};

const Map = ({ filteredStatus, onUpdateMarkers }) => {
  const [locations, setLocations] = useState([]);
  const [popupContent, setPopupContent] = useState(null);
  //to get vehicle locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/v0/get-live-location`
        );

        const data = response.data;
        console.log(data);

        const formattedData = data.map((item) => ({
          coords: [item.longitude, item.latitude],
          status: item.status || "unknown",
          vehicleNumber: item.vehicleNumber,
          loc: item.location,
          timestamp: item.lastPacketReceivedAt,
        }));

        setLocations(formattedData);
      } catch (error) {
        console.error("Failed to fetch locations", error);
      }
    };

    fetchLocations();
  }, []);

  //maps
  useEffect(() => {
    const olaMaps = new OlaMaps({
      apiKey: process.env.REACT_APP_OLA_MAPS_API_KEY,
    });

    const container = document.getElementById("map-container");
    let myMap;

    if (container) {
      myMap = olaMaps.init({
        container: "map-container",
        style:
          "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
        center: [72.97043, 19.172254],
        zoom: 12,
      });

      console.log("Filtered Status:", filteredStatus); // Debugging
      console.log("Available Locations:", locations); // Debugging
      const filteredLocations =
        filteredStatus === "all"
          ? locations
          : locations.filter((location) => {
              console.log(`Checking Status: ${location.status}`); // Debugging
              return (
                (filteredStatus === "running" &&
                  location.status.toLowerCase().includes("moving")) ||
                (filteredStatus === "stopped" &&
                  location.status.toLowerCase().includes("stopped")) ||
                (filteredStatus === "no_network" &&
                  location.status.toLowerCase().includes("no data")) ||
                (filteredStatus === "unknown" &&
                  location.status.toLowerCase().includes("idling"))
              );
            });

      filteredLocations.forEach((location) => {
        // const dot = document.createElement("div");
        // dot.style.width = "12px";
        // dot.style.height = "12px";
        // dot.style.borderRadius = "50%";
        // dot.style.backgroundColor =
        //   location.status === "stopped"
        //     ? "red"
        //     : location.status === "no_network"
        //     ? "orange"
        //     : "green";
        // dot.style.border = "2px solid white";

        //truck icon
        const truckIcon = document.createElement("img");
        truckIcon.src = location.status.includes("Stopped")
          ? stoppedTruck
          : location.status.includes("No data")
          ? notWorkingTruck
          : location.status.includes("Moving")
          ? movingTruck
          : defaultTruck; // Adjust paths based on your file structure
        truckIcon.style.width = "40px"; // Adjust the size as needed
        truckIcon.style.height = "40px";
        truckIcon.style.borderRadius = "50%";
        const marker = olaMaps
          .addMarker({ element: truckIcon, offset: [0, -10], anchor: "center" })
          .setLngLat(location.coords)
          .addTo(myMap);

        marker.getElement().addEventListener("click", () => {
          console.log(location);
          setPopupContent({
            vehicleNumber: location.vehicleNumber,
            coords: location.coords,
            status: location.status,
            Location: location.loc,
            timestamp: location.timestamp,
          });
        });
      });

      onUpdateMarkers(locations);
    }

    return () => {
      if (myMap) myMap.remove();
    };
  }, [locations, filteredStatus, onUpdateMarkers]);

  return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
      <div id="map-container" style={{ height: "100%", width: "100%" }}></div>
      {popupContent && (
        <div
          style={{
            position: "absolute",
            left: `${popupContent.coords[0]}px`,
            top: `${popupContent.coords[1]}px`,
            transform: "translate(-10%, 10%)",
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <p>
            <b>Vehicle:</b> {popupContent.vehicleNumber}
          </p>
          <p>
            <b>Status:</b> {popupContent.status}
          </p>
          <p>
            <b>Last Location:</b>{" "}
            {popupContent.Location.length > 75
              ? popupContent.Location.slice(0, 75) + "..."
              : popupContent.Location}
          </p>
          <p>
            <b>Last Updated:</b> {popupContent.timestamp}
          </p>
        </div>
      )}
    </div>
  );
};

const MapPage = () => {
  const [filteredStatus, setFilteredStatus] = useState("all");
  const [statusCounts, setStatusCounts] = useState({
    all: 0,
    running: 0,
    stopped: 0,
    no_network: 0,
    unknown: 0,
  });

  const handleUpdateMarkers = useCallback((locations) => {
    const counts = {
      all: locations.length,
      running: locations.filter((loc) => loc.status.includes("Moving")).length,
      stopped: locations.filter((loc) => loc.status.includes("Stopped")).length,
      no_network: locations.filter((loc) => loc.status.includes("No data"))
        .length,
      unknown: locations.filter((loc) => loc.status.includes("Idling")).length,
    };
    setStatusCounts(counts);
  }, []);

  const statuses = [
    {
      label: "All Vehicles",
      color: "blue",
      key: "all",
      count: statusCounts.all,
    },
    {
      label: "Running",
      color: "green",
      key: "running",
      count: statusCounts.running,
    },
    {
      label: "Stopped",
      color: "red",
      key: "stopped",
      count: statusCounts.stopped,
    },
    {
      label: "Not Working",
      color: "orange",
      key: "no_network",
      count: statusCounts.no_network,
    },
    {
      label: "Idling",
      color: "black",
      key: "unknown",
      count: statusCounts.unknown,
    },
  ];

  return (
    <div>
      <StatusBar statuses={statuses} onStatusClick={setFilteredStatus} />
      <Map
        filteredStatus={filteredStatus}
        onUpdateMarkers={handleUpdateMarkers}
      />
    </div>
  );
};

export default MapPage;
