import React, { useState } from "react";
import "./DailyRunningKm.css";

const DailyRunningKm = () => {
  const [distanceRanges, setDistanceRanges] = useState([
    { label: "<100 KM", count: 5 },
    { label: "100-200 KM", count: 8 },
    { label: ">200 KM", count: 7 },
  ]);
  const [totalVehicles, setTotalVehicles] = useState(20);
  const [isEditing, setIsEditing] = useState(false);

  const [vehicleData, setVehicleData] = useState([
    {
      vehicleNo: "V123",
      driverName: "John Doe",
      unscheduledDuration: "<20 Min",
      geofenceDuration: "<20 Min",
      idling: "<5 Min",
      dailyKm: "<100 KM",
    },
    {
      vehicleNo: "V124",
      driverName: "Jane Doe",
      unscheduledDuration: "20-60 Min",
      geofenceDuration: "20-60 Min",
      idling: "5-15 Min",
      dailyKm: "100-200 KM",
    },
    {
      vehicleNo: "V125",
      driverName: "Alice",
      unscheduledDuration: ">1 Hrs",
      geofenceDuration: ">1 Hrs",
      idling: ">15 Min",
      dailyKm: ">200 KM",
    },
  ]);

  const handleRangeChange = (index, value) => {
    const updatedRanges = [...distanceRanges];
    const oldLabel = updatedRanges[index].label;
    updatedRanges[index].label = value;
    setDistanceRanges(updatedRanges);

    // Update DailyRunningKm in vehicleData dynamically
    setVehicleData((prevData) =>
      prevData.map((vehicle) =>
        vehicle.dailyKm === oldLabel ? { ...vehicle, dailyKm: value } : vehicle
      )
    );

    setTotalVehicles(
      updatedRanges.reduce((sum, range) => sum + range.count, 0)
    );
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="dashboard-container">
      {/* Daily Running KM Summary Section */}
      <div className="daily-running-km-summary">
        <div className="table-header">
          <h2>Daily Running KM</h2>
          <button onClick={handleEditToggle}>
            {isEditing ? "Save Changes" : "Customize"}
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Distance Range</th>
              <th>Vehicles</th>
            </tr>
          </thead>
          <tbody>
            {distanceRanges.map((range, index) => (
              <tr key={index}>
                <td>
                  {isEditing ? (
                    <input
                      type="text"
                      value={range.label}
                      onChange={(e) => handleRangeChange(index, e.target.value)}
                    />
                  ) : (
                    range.label
                  )}
                </td>
                <td>{range.count}</td>
              </tr>
            ))}
            <tr className="grand-total">
              <td>Grand total</td>
              <td>{totalVehicles}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* List View Table */}
      <div className="list-view">
        <table>
          <thead>
            <tr>
              <th>Vehicle No</th>
              <th>Driver Name</th>
              <th>Unscheduled Stoppage</th>
              <th>Time Duration</th>
              <th>Geofence Stoppage</th>
              <th>Time Duration</th>
              <th>Idling</th>
              <th>Time Duration</th>
              <th>Daily Running KM</th>
              <th>KM</th>
            </tr>
          </thead>
          <tbody>
            {vehicleData.map((vehicle, index) => (
              <tr key={index}>
                <td>{vehicle.vehicleNo}</td>
                <td>{vehicle.driverName}</td>
                <td>{vehicle.unscheduledDuration}</td>
                <td>--</td>
                <td>{vehicle.geofenceDuration}</td>
                <td>--</td>
                <td>{vehicle.idling}</td>
                <td>--</td>
                <td>{vehicle.dailyKm}</td>
                <td>--</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DailyRunningKm;
